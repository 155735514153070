import Swal from 'sweetalert2'
import numeral from 'numeral'
import numeralen from "numeral/locales/es"

(function() {
  'use strict';

  /**
   * Update totals and badges
   * @param {Int} total, {Int}, {Int} quantity
  */
  function updateTotals(total, price, quantity, opt) {
    var $totalDiv = $('.cart-subtotal');
    total = parseInt(total);
    if (opt == 'add') {
      $totalDiv.html('<span>Subtotal: </span><strong>' +
        (numeral(total).format('$0,0[.]0000')) + '</strong>');
      $('#cart-subtotal').data('subtotal', (parseInt(total) +
          parseInt(quantity * price)));
      $('.cart-title').html('Mi Carro <strong>' + (numeral(parseInt(total)).format('$0,0[.]0000')) + '</strong>');
    } else {
      $totalDiv.html('<span>Subtotal</span><strong>' + (numeral(total).format('$0,0[.]0000')) + '</strong>');
      $('#cart-subtotal').data('subtotal', (total));
      $('.cart-title').html('Mi Carro <strong>' + (numeral(parseInt(total)).format('$0,0[.]0000')) + '</strong>');
    }
    // cart view
    $('#total-products').html(numeral(total).format('$0,0[.]0000'));
    $('#total-price').html(numeral(total).format('$0,0[.]0000'));
  }

  /**
   * Update mini cart
   * @param {Object} cart
  */
  function updateMiniCart(cart) {
    // Divs
    var $miniCartTableDiv;
    var miniCartDiv = '';
    // Cart
    var item;
    var item_image;

    $miniCartTableDiv = $('.cart-dropdown-body');
    for (var i in cart) {
      item = cart[i];
      var product = $.grep(
          products, function(e) { return JSON.parse(e).id == parseInt(item.product_pk); });
      product = JSON.parse(product);

      miniCartDiv += '<li class="single-cart-item"><div class="cart-img"><a' +
      ' href="/products/detail/' + item.product_pk  + '">' +
      '<img src="' + product.image_thumbnail +
      '" alt="img">' +
      '</div></a><div class="cart-content"><h5 class="product-name"><a href="products/detail/' + item.product_pk + '">' +
      product.name + '</a></h5>' +
      '<span class="cart-price">' +
      item.quantity + ' x ' +
      numeral(item.price).format('$0,0[.]0000') + '</span></div>' +
      '<div class="js-remove-mini-cart"><a href="" title="Remove" data-quantity="' + item.quantity +
      '" data-product-id="' + item.product_pk + '" data-price="' +
      item.price +
      '"><i class="fa fa-times" data-product-id="' + item.product_pk + '" data-quantity="' + item.quantity +
      '" data-price="' + item.price + '"  ></i>' +
      '</a></div></div>';
    }
    $miniCartTableDiv.html(miniCartDiv);
  }

  /**
   * Add item to cart
   * @param {Object} e
  */
  $('.js-add-cart').click(function(e) {
      var $this = $(e.target);
      e.preventDefault();
      total = parseInt($('#cart-subtotal').data('subtotal'));

      // Data
      var data;
      var url;
      // Cart
      var total;
      var price;
      var quantity;
      var response;
      // Divs
      var $totalDiv;
      var $priceDiv;
      var $itemQuantityDiv;
      var $itemQuantityDetailDiv;
      var $productMessage;
      var $productMessageDetail;

      $priceDiv = $this.closest('.product-content').find('.regular-price');
      $itemQuantityDiv = $this.closest('.product-content').find('.item-quantity');
      $itemQuantityDetailDiv = $this.closest('.product-details-content').find('.item-quantity');
      price = parseInt($priceDiv.data('price'));
      $productMessage = $this.closest('.product-content').find('.product-message');
      $productMessageDetail = $this.closest('.product-details-content').find('.product-message');
      if ($itemQuantityDiv.length != 0) {
        quantity = 1;
      } else {
        quantity = $('#product-quantity').val();
      }
      data = {
          'product_id': $this.data('product-id'),
          'quantity': quantity,
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/add/';

      $.ajax({
          url: url,
          type: 'POST',
          data: data,
          success: function(response) {
            if (response.success == true) {
              $itemQuantityDiv.closest('h5').removeClass('d-none');
              $itemQuantityDiv.html(parseInt($itemQuantityDiv.text()) + 1);
              $itemQuantityDiv.html($itemQuantityDiv.text() + ' ');

              $itemQuantityDetailDiv.closest('h6').show();
              $itemQuantityDetailDiv.html(
                parseInt($itemQuantityDetailDiv.text()) + parseInt(quantity));
              $itemQuantityDetailDiv.html($itemQuantityDetailDiv.text() + ' ');

              $productMessage.removeClass('d-none');
              $productMessageDetail.removeClass('d-none');
              updateTotals(response.total, response.price, quantity, 'add');
              updateMiniCart(response.cart);
            } else {
              $itemQuantityDiv.closest('h5').html(
                '<strong>Nos hemos quedado sin stock</strong>');
              $itemQuantityDiv.closest('h5').show();
              $itemQuantityDetailDiv.closest('h5').html(
                '<strong>Nos hemos quedado sin stock</strong>');
              $itemQuantityDetailDiv.closest('h5').show();
            }
          }
      });
  });

  /**
   * Change item quantity in shopping cart view
   * @param {Object} e
  */
  $('.quantitySniper').change(function(e) {
      var $this = $(e.target);
      // Data
      var data;
      var url;
      // Cart
      var quantity;
      var response;
      // Divs
      var $totalDiv;
      var $priceDiv;

      quantity = parseInt($this.val());
      var totalItemPrice = $this.closest(
        '.cart-item').find('.product-subtotal');

      if (quantity == 0) {
        $this.val(1);
      } else {
        data = {
            'product_id': $this.data('product-id'),
            'quantity': quantity,
            'csrfmiddlewaretoken': csrfmiddlewaretoken
        };
        url = '/orders/cart/ajax-update/';
        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: function(response) {
              updateTotals(response.total, response.price, 1, 'add');
              updateMiniCart(response.cart);
              totalItemPrice.html('$' + (response.price * quantity));
            }
        });
      }
  });

  /**
   * Remove item from mini cart
   * @param {Object} e
  */
  $(document).on('click', '.js-remove-mini-cart', function(e) {
      var $this;
      var data;
      var url;
      var price;
      var quantity;
      var total;
      var $item;

      e.preventDefault();
      $this = $(e.target);
      var productId = $this.data('product-id');
      quantity = $this.data().quantity;

      var miniCartItem = $this[0].closest('.media');

      data = {
          'product_id': $this.data('product-id'),
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/remove/',

      Swal.fire({
        title: '¿Estás seguro?',
        type: 'warning',
        confirmButtonText: 'Borrar',
        confirmButtonClass: 'green-jungle',
        showCancelButton: true
      }).then(
      function() {
        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: function(response) {
              $(miniCartItem).addClass('d-none');
              updateTotals(
                response.total, response.price, quantity, 'remove');
              updateMiniCart(response.cart);

              // Hide product message
              for (var i=0;i<$('.product-message').length;i++) {
                var message = $('.product-message')[i];
                if ($(message).data('product-id') == productId) {
                  $(message).addClass('d-none');
                }
              }
              // Reset product quantity
              for (var i=0;i<$('.item-quantity').length;i++) {
                var itemQuantity = $('.item-quantity')[i];
                if ($(itemQuantity).data('product-id') == productId) {
                  $(itemQuantity).text('0');
                }
              }
            }
        });
      }, function(dismiss) {
      });

  });
  /**
   * Remove item from cart
   * @param {Object} e
  */
  $(document).on('click', '.js-remove-cart', function(e) {
      var $this;
      var data;
      var url;
      var price;
      var quantity;
      var total;
      var $item;
      e.preventDefault();

      $this = $(e.target);
      quantity = parseInt(
        $this.closest('.cart-item-body').find('.cart-qty input').val());

      var cartItem = $this[0].closest('.cart-item');
      $productMessage = $this.closest('.product-content').find('.product-message');

      data = {
          'product_id': $this.data('product-id'),
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };
      url = '/orders/cart/remove/',

      Swal.fire({
        title: '¿Estás seguro?',
        type: 'warning',
        confirmButtonText: 'Borrar',
        confirmButtonClass: 'green-jungle',
        showCancelButton: true
      }).then(
      function() {
        $.ajax({
            url: url,
            type: 'POST',
            data: data,
            success: function(response) {
              $(cartItem).addClass('d-none');
              updateTotals(
                response.total, response.price, quantity, 'remove');
              updateMiniCart(response.cart);
              $productMessage.addClass('d-none');
            }
        });
      }, function(dismiss) {
      });
  });
  /**
   * Purchase a cart
   * @param {Object} e
  */
  $('.js-purchase').click(function(e) {
      var $this;
      var data;
      var url;
      $this = $(e.target);
      url = '/orders/cart/purchase/',
      data = {
          'csrfmiddlewaretoken': csrfmiddlewaretoken
      };

      $.ajax({
          url: url,
          type: 'POST',
          data: data,
          success: function(response) {
          }
      });
  });
  /**
   * Changes the quantity selector
   * @param {Object} e
  */
  $('.up-down').on('click', function() {
      var $button = $(this);
      var oldValue = $button.closest('.quantity').find(
        'input.quantity-input').val();
      if ($button.text() == '+') {
          var newVal = parseFloat(oldValue) + 1;
      } else {
          // Don't allow decrementing below zero
          if (oldValue > 0) {
              var newVal = parseFloat(oldValue) - 1;
          } else {
              newVal = 0;
          }
      }

      $button.closest('.quantity').find('input.quantity-input').val(newVal);

  });
  /**
   * Submit quantity form
   * @param {Object} e
  */
  $('.js-update-cart').click(function(e) {
    $('#updateCart').submit();
  });
}());
